import { createWebHistory } from '@ionic/vue-router'
import { createRouter } from '@ionic/vue-router'
import { computed, ref } from 'vue'

import { auth } from '@/firebase-config'

import useEditorGuards from '@/composables/global/use-editor-guards'
import useInitLoader from '@/composables/global/use-init-loader'
import useLogs from '@/composables/global/use-logs'
import useProgress from '@/composables/global/use-progress'
import useShellHeader from '@/composables/global/use-shell-header'
import useThemes from '@/composables/global/use-themes'
import useUsers from '@/composables/global/use-users'
import useViewer from '@/composables/global/use-viewer'

import { stopSpeaking } from '@/utils/text-to-speech'

export const ACTIVITIES_PATH = '/activities'
export const CONVERSATION_BANK_PATH = '/conversation_bank'
export const BRUSH_TOOL_PATH = '/brush_tool'
export const CHAT_PATH = '/chat'
export const COURSES_PATH = '/courses'
export const HOME_PATH = '/home'
export const FIRST_STEP_ORGANIZER_PATH = '/organizer'
export const LEVELS_PATH = '/levels'
export const LOGIN_PATH = '/login'
export const MOOD_RATING_PATH = '/mood'
export const PLAN_PATH = '/plan'
export const REACHABLE_NEXT_STEP = '/reachable_next_step'
export const REMINDERS_PATH = '/reminders'
export const RESOURCES_PATH = '/resources'
export const SETTINGS_PATH = '/settings'
export const SIBTIME_MOMENTS_PATH = '/sibtime_moments'
export const USER_AGREEMENTS_PATH = '/agreements'
export const USERS_PATH = '/users'
export const VIDEOS_PATH = '/videos'
export const VIDEO_CALL_PATH = '/video_call'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: LOGIN_PATH,
        },
        {
            path: ACTIVITIES_PATH,
            name: 'ActivitiesPage',
            component: () => import('./views/ActivitiesPage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: BRUSH_TOOL_PATH,
            redirect: `${BRUSH_TOOL_PATH}/countdown`,
        },
        {
            path: `${BRUSH_TOOL_PATH}/:tabId`,
            name: 'BrushTool',
            component: () => import('./views/BrushToolPage.vue'),
            props: (route) => ({ tabId: route.params.tabId }),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: CHAT_PATH,
            name: 'ChatList',
            component: () => import('./views/ChatList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: CONVERSATION_BANK_PATH,
            name: 'ConversationList',
            component: () => import('./views/ConversationList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: COURSES_PATH,
            name: 'CourseList',
            component: () =>
                import(/* webpackPrefetch: true */ './views/CourseList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: HOME_PATH,
            name: 'HomePage',
            component: () =>
                import(/* webpackPrefetch: true */ './views/HomePage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: FIRST_STEP_ORGANIZER_PATH,
            name: 'FirstStepOrganizerPage',
            component: () => import('./views/FirstStepOrganizerPage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${FIRST_STEP_ORGANIZER_PATH}/student/:studentId`,
            name: 'FirstStepStudentPage',
            component: () => import('./views/FirstStepStudentPage.vue'),
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: LEVELS_PATH,
            name: 'LevelList',
            component: () =>
                import(/* webpackPrefetch: true */ './views/LevelList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `/levels/:levelId/pages/:pageId`,
            name: 'LevelPage',
            component: () => import('./views/LevelPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: LOGIN_PATH,
            name: 'LoginPage',
            component: () =>
                import(/* webpackPrefetch: true */ './views/LoginPage.vue'),
        },
        {
            path: `${LOGIN_PATH}/create-account`,
            name: 'LoginPageCreateAccount',
            component: () =>
                import(
                    /* webpackPrefetch: true */ './views/CreateAccountPage.vue'
                ),
            meta: {
                requiresMissingUser: true,
            },
        },
        {
            path: MOOD_RATING_PATH,
            name: 'MoodRatingPage',
            component: () => import('./views/MoodRatingPage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: PLAN_PATH,
            name: 'PlanPage',
            component: () => import('./views/PlanPage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${REACHABLE_NEXT_STEP}/:levelNum`,
            name: 'ReachableNextStepPage',
            component: () => import('./views/ReachableNextStepPage.vue'),
            props: (route) => ({ levelNum: Number(route.params.levelNum) }),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: RESOURCES_PATH,
            name: 'ResourceList',
            component: () =>
                import(/* webpackPrefetch: true */ './views/ResourceList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: SETTINGS_PATH,
            name: 'SettingsPage',
            component: () =>
                import(/* webpackPrefetch: true */ './views/SettingsPage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: SIBTIME_MOMENTS_PATH,
            name: 'SibtimeMoments',
            component: () => import('./views/SibtimeMomentsPage.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${USER_AGREEMENTS_PATH}/:document`,
            name: 'UserAgreementsPage',
            component: () => import('./views/UserAgreementsPage.vue'),
        },
        {
            path: USERS_PATH,
            name: 'UserList',
            component: () =>
                import(/* webpackPrefetch: true */ './views/UserList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/log-report`,
            name: 'UsersLogReportPage',
            component: () => import('./views/UsersLogReportPage.vue'),
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/teacher-report`,
            name: 'UsersTeacherReportPage',
            component: () => import('./views/UsersTeacherReportPage.vue'),
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/adverse-event-report`,
            name: 'UsersAdverseEventReportPage',
            component: () => import('./views/UsersAdverseEventReportPage.vue'),
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/guide-sessions-report`,
            name: 'UsersGuideSessionReportPage',
            component: () => import('./views/UsersGuideSessionReportPage.vue'),
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/trainer-report`,
            name: 'UsersProgressReportPage',
            component: () => import('./views/UsersProgressReportPage.vue'),
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/columbia-self-reports`,
            name: 'ColumbiaAndPhq9ReportList',
            component: () => import('./views/ColumbiaAndPhq9ReportList.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/columbia-self-reports/:reportId`,
            name: 'ColumbiaAndPhq9ReportPage',
            component: () => import('./views/ColumbiaAndPhq9ReportPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/guide-sessions`,
            name: 'GuideSessionList',
            component: () => import('./views/GuideSessionList.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/guide-sessions/:sessionId`,
            name: 'GuideSessionPage',
            component: () => import('./views/GuideSessionPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/quiz-result`,
            name: 'UserQuizResultsPage',
            component: () => import('./views/UserQuizResultsPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/uploads`,
            name: 'UserVideoList',
            component: () => import('./views/VideoList.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: `${USERS_PATH}/:userId/uploads/:uploadId/:option`,
            name: 'UserVideoPage',
            component: () => import('./views/VideoPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: VIDEOS_PATH,
            name: 'VideoList',
            component: () => import('./views/VideoList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${VIDEO_CALL_PATH}/:userId/:chatId/:kind`,
            name: 'CallPage',
            component: () => import('./views/CallPage.vue'),
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${VIDEOS_PATH}/:uploadId/:option`,
            name: 'VideoPage',
            component: () => import('./views/VideoPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: REMINDERS_PATH,
            name: 'ReminderList',
            component: () => import('./views/ReminderList.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `${REMINDERS_PATH}/:reminderId`,
            name: 'ReminderPage',
            component: () => import('./views/ReminderPage.vue'),
            props: true,
            meta: {
                isSubRoute: true,
                requiresAuth: true,
            },
        },
        {
            path: '/network-issue',
            name: 'NetworkIssue',
            component: () => import('./views/NetworkIssue.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/:catchAll(.*)*',
            name: 'NotFound',
            component: () => import('./views/NotFound.vue'),
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
    ],
})

export const preAuthRoute = ref<string | undefined>()

// Route guarding
router.beforeEach(async (to, from, next) => {
    const { confirmDiscardUnsavedChanges } = useEditorGuards()
    const { viewer } = useViewer()
    const currentUser = auth.currentUser
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    const requiresMissingUser = to.matched.some(
        (record) => record.meta.requiresMissingUser
    )

    if (to.path !== from.path) {
        // Confirm discard changes only if path changes (ignore search and hash)
        const cancelRouteChange = !(await confirmDiscardUnsavedChanges())
        if (cancelRouteChange) return next(from)
    }

    if (requiresAuth && !currentUser) {
        preAuthRoute.value = to.fullPath
        next(LOGIN_PATH)
    } else if (
        requiresMissingUser &&
        currentUser &&
        viewer.value !== undefined
    ) {
        next(HOME_PATH)
    } else if (currentUser && to.path === LOGIN_PATH) {
        if (to.query.redirectedfrom === 'clever') {
            // User was redirected from Clever, add query param to re-init user
            next(`${HOME_PATH}?redirectedfrom=clever`)
        } else {
            next(HOME_PATH)
        }
    } else {
        next()
    }

    // If navigating to a subroute, set left toolbar button to back button
    // Otherwise set left toolbar button to menu toggle
    const { setMenuButtonBack } = useShellHeader()
    if (to.name !== from.name) {
        const isSubRoute = to.matched.some((record) => record.meta.isSubRoute)
        setMenuButtonBack(isSubRoute)
    }
})

router.afterEach(async (to) => {
    await stopSpeaking()

    const isLoginPage = to.matched.some((record) => record.name === 'LoginPage')
    if (isLoginPage) return

    const { appDataInitialized } = useInitLoader()
    const { throttledUpdateActiveTime } = useViewer()

    await appDataInitialized()
    throttledUpdateActiveTime()

    const requiresUser = 'userId' in to.params
    if (requiresUser && to.name !== 'CallPage') {
        const { isUserInActiveCourse } = useUsers()

        const userId = to.params.userId as string
        const userExists = await isUserInActiveCourse(userId)
        if (!userExists) {
            return router.push({ name: 'NotFound', params: { type: 'user' } })
        }
    }

    const { addLog } = useLogs()
    const { updateProgress } = useProgress()

    const isLevelPage = to.matched.some((record) => record.name === 'LevelPage')
    if (isLevelPage) {
        const levelId = to.params.levelId as string
        const pageId = to.params.pageId as string
        // Update viewer progress
        updateProgress(levelId, pageId)
    } else {
        // Reset LevelPage specific app-wide styles
        const { setToolbarStyle } = useShellHeader()
        const { setAltBackground } = useThemes()
        setToolbarStyle({})
        setAltBackground()

        addLog()
    }
})

export const EDIT_HASH = '#edit'

export const hasEditHash = computed(
    () => router.currentRoute.value.hash === EDIT_HASH
)

export const currentRouteHash = computed(() =>
    router.currentRoute.value.hash.replace('#', '')
)

export const currentRouteQuery = computed(() => router.currentRoute.value.query)

export const isRedirectedFromClever = computed(
    () => currentRouteQuery.value.redirectedfrom === 'clever'
)

export default router
