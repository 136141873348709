import { App } from '@capacitor/app'
import { ref, watch } from 'vue'

import { auth } from '@/firebase-config'
import router, { LOGIN_PATH } from '@/router'

import useActivities from '@/composables/global/use-activities'
import useAgencies from '@/composables/global/use-agencies'
import useChats from '@/composables/global/use-chats'
import useColumbiaSelfReport from '@/composables/global/use-columbia-self-report'
import useCourses from '@/composables/global/use-courses'
import useDisplayMode from '@/composables/global/use-display-mode'
import useEnrollmentCodes from '@/composables/global/use-enrollment-codes'
import useGuideSessions from '@/composables/global/use-guide-sessions'
import useLevelPage from '@/composables/global/use-level-page'
import useLevelPageElements from '@/composables/global/use-level-page-elements'
import useLevelPageQuizMedia from '@/composables/global/use-level-page-quiz-media'
import useLevelPageQuizSummary from '@/composables/global/use-level-page-quiz-summary'
import useLevelPageReviewQuiz from '@/composables/global/use-level-page-review-quiz'
import useLevels from '@/composables/global/use-levels'
import useLogs from '@/composables/global/use-logs'
import useMoodRatings from '@/composables/global/use-mood-ratings'
import usePhq9 from '@/composables/global/use-phq9'
import usePlanPage from '@/composables/global/use-plan-page'
import useProgress from '@/composables/global/use-progress'
import useQuiz from '@/composables/global/use-quiz'
import useReachableNextSteps from '@/composables/global/use-reachable-next-steps'
import useReminders from '@/composables/global/use-reminders'
import useResources from '@/composables/global/use-resources'
import useResourcesElements from '@/composables/global/use-resources-elements'
import useShellHeader from '@/composables/global/use-shell-header'
import useSibtimeMoments from '@/composables/global/use-sibtime-moments'
import useUploadNotes from '@/composables/global/use-upload-notes'
import useUsers from '@/composables/global/use-users'
import useViewer from '@/composables/global/use-viewer'
import useVoiceResponses from '@/composables/global/use-voice-responses'

import usePushNotifications from '@/composables/use-push-notifications'
import useUserUploads from '@/composables/use-user-uploads'

import { logger } from '@/utils/debug'
import { errorAlert } from '@/utils/ion-alert'

const debug = false

const isAppInitializing = ref(true)
const appInitializingResolvers: (() => void)[] = []

watch(isAppInitializing, () => {
    if (!isAppInitializing.value) {
        appInitializingResolvers.forEach((resolve) => resolve())
    }
})

// Ensure global app data gets loaded before any components that depend on it
export default function () {
    const { deinitActivities } = useActivities()
    const { deinitAgencies, fetchAgencies } = useAgencies()
    const { initChats, deinitChats } = useChats()
    const { deinitColumbiaSelfReport, loadColumbiaSelfReports } =
        useColumbiaSelfReport()
    const { deinitCourses, fetchCourses, loadCourse } = useCourses()
    const { setDisplayMode } = useDisplayMode()
    const { deinitEnrollmentCodes, initEnrollmentCodes } = useEnrollmentCodes()
    const { deinitSessions } = useGuideSessions()
    const { deinitLevelPage } = useLevelPage()
    const { deinitLevelPageElements } = useLevelPageElements()
    const { deinitLevelPageFeedbackMedia } = useLevelPageQuizMedia()
    const { deinitLevelPageReviewQuiz } = useLevelPageReviewQuiz()
    const { deinitLevelPageQuizSummary } = useLevelPageQuizSummary()
    const { deinitLevels } = useLevels()
    const { addLog, deinitLogs } = useLogs()
    const { deinitMoodRatings } = useMoodRatings()
    const { deinitPhq9, loadPhq9Responses } = usePhq9()
    const { deinitPlanPage } = usePlanPage()
    const { deinitProgress } = useProgress()
    const { deinitQuiz } = useQuiz()
    const { deinitReachableNextSteps } = useReachableNextSteps()
    const { deinitReminders } = useReminders()
    const { deinitResourcesElements } = useResourcesElements()
    const { deinitResources } = useResources()
    const { initSibtimeMoments, deinitSibtimeMoments } = useSibtimeMoments()
    const { deinitShellHeader } = useShellHeader()
    const { deinitUploadNotes } = useUploadNotes()
    const { deinitUsers } = useUsers()
    const { deinitUserUploads } = useUserUploads()
    const { viewer, viewersCourseIds, deinitViewer, updateLoginTime } =
        useViewer()
    const { deinitVoiceResponses } = useVoiceResponses()
    const { deinitPushNotifications, initPushNotifications } =
        usePushNotifications()

    /**
     * Clears user's global state and event listeners.
     */
    async function deinitAppData() {
        logger(debug)

        isAppInitializing.value = true

        await addLog({ isActive: false })

        App.removeAllListeners()

        setDisplayMode('auto')
        deinitActivities()
        deinitAgencies()
        deinitChats()
        deinitColumbiaSelfReport()
        deinitCourses()
        deinitEnrollmentCodes()
        deinitSessions()
        deinitLevelPage()
        deinitLevelPageElements()
        deinitLevelPageFeedbackMedia()
        deinitLevelPageReviewQuiz()
        deinitLevelPageQuizSummary()
        deinitLevels()
        deinitLogs()
        deinitMoodRatings()
        deinitPhq9()
        deinitPlanPage()
        deinitProgress()
        deinitPushNotifications()
        deinitQuiz()
        deinitReachableNextSteps()
        deinitReminders()
        deinitResourcesElements()
        deinitResources()
        deinitSibtimeMoments()
        deinitShellHeader()
        deinitUploadNotes()
        deinitUsers()
        deinitUserUploads()
        deinitVoiceResponses()

        await deinitViewer()
    }

    /**
     * Fetches core app data including viewer data and course data. Should
     * only be called once, either when a user signs in or when the app is
     * being initialized from an existing session.
     */
    async function initAppData() {
        logger(debug)

        try {
            let activeCourse = viewer.value?.activeCourse
            if (!activeCourse) {
                if (viewersCourseIds.value?.length > 0) {
                    activeCourse = viewersCourseIds.value[0]
                } else {
                    throw 'Missing course enrollment'
                }
            }

            await Promise.all([fetchAgencies(), fetchCourses()])
            await Promise.all([
                loadCourse(activeCourse),
                loadColumbiaSelfReports(),
                loadPhq9Responses(),
                initChats(),
                initSibtimeMoments(),
                initEnrollmentCodes(),
            ])

            isAppInitializing.value = false

            // Initialization that doesn't need to block app rendering
            initPushNotifications()
            updateLoginTime()
        } catch (error) {
            await errorAlert({ error })

            // Fail completely if unable to initalize core app data
            await deinitAppData()
            await auth.signOut()
            router.push(LOGIN_PATH)
        }
    }

    /**
     * @returns a promise that resolves when `initAppData` resolves, or immediately if `initAppData` has already finished
     */
    async function appDataInitialized() {
        return new Promise<void>((resolve) => {
            if (isAppInitializing.value) {
                // App still initializing, resolve when finished
                appInitializingResolvers.push(resolve)
            } else {
                // App initialized, resolve immediately
                resolve()
            }
        })
    }

    return {
        appDataInitialized,
        deinitAppData,
        initAppData,
    }
}
