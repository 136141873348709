import { SplashScreen } from '@capacitor/splash-screen'
import { IonicVue } from '@ionic/vue'
import { addIcons } from 'ionicons/components'
import {
    // Ionicons to be registered globally
    add,
    addCircleOutline,
    alertCircle,
    analytics,
    analyticsOutline,
    arrowBack,
    arrowBackCircle,
    arrowForward,
    arrowForwardCircle,
    arrowUp,
    arrowDown,
    book,
    bookmarks,
    call,
    callOutline,
    calendar,
    camera,
    caretBack,
    caretForward,
    caretUpCircle,
    caretDownCircle,
    caretDownOutline,
    caretUpOutline,
    chatbox,
    checkmark,
    checkmarkCircleOutline,
    chevronBack,
    chevronDown,
    chevronForward,
    chevronUp,
    close,
    cloudUpload,
    contract,
    colorPalette,
    create,
    createOutline,
    documentText,
    download,
    downloadOutline,
    duplicate,
    ear,
    ellipse,
    ellipsisHorizontal,
    ellipsisHorizontalOutline,
    ellipseOutline,
    ellipsisVerticalOutline,
    expand,
    extensionPuzzle,
    eye,
    eyeOff,
    exitOutline,
    film,
    filterOutline,
    footsteps,
    heart,
    helpCircleOutline,
    home,
    image,
    informationCircleOutline,
    layers,
    library,
    list,
    lockClosed,
    logoClosedCaptioning,
    lockOpen,
    logoYoutube,
    mail,
    mailUnread,
    medkitOutline,
    menu,
    mic,
    musicalNotes,
    newspaper,
    notifications,
    openOutline,
    pieChart,
    pieChartOutline,
    pencil,
    people,
    person,
    personAdd,
    pause,
    pauseCircle,
    play,
    playCircle,
    playCircleOutline,
    playBack,
    playForward,
    pulseOutline,
    radioButtonOn,
    readerOutline,
    recordingOutline,
    refresh,
    refreshOutline,
    remove,
    removeCircleOutline,
    repeat,
    ribbon,
    save,
    scan,
    scanCircle,
    send,
    settings,
    shapes,
    speedometer,
    star,
    statsChart,
    stop,
    stopwatch,
    square,
    sunny,
    text,
    time,
    thumbsUp,
    trailSign,
    trash,
    videocam,
    videocamOutline,
    volumeHigh,
    volumeMedium,
    volumeLow,
    volumeOff,
    warning,
} from 'ionicons/icons'
import { createApp, App } from 'vue'

import ViviApp from '@/App.vue'
import { initDomPurify } from '@/dompurify-config'
import { auth } from '@/firebase-config'
import i18n from '@/i18n-config'
import Router from '@/router'

import useAdminSettings from '@/composables/global/use-admin-settings'
import useDisplayMode from '@/composables/global/use-display-mode'
import useInitLoader from '@/composables/global/use-init-loader'
import useViewer from '@/composables/global/use-viewer'

import '@ionic/vue/css/ionic.bundle.css'

let app: App<Element> | undefined = undefined

auth.onAuthStateChanged(async (user) => {
    const { initAppData } = useInitLoader()
    const { setDisplayMode, setInitDisplayTheme } = useDisplayMode()
    const { viewer, initViewer, updateViewerSettings } = useViewer()

    if (app === undefined) {
        // App cold start
        app = createApp(ViviApp)
        app.use(IonicVue, { mode: 'md' })
        app.use(Router)
        app.use(i18n)

        initDomPurify()

        const { initAdminSettings } = useAdminSettings()
        await initAdminSettings()
        await setInitDisplayTheme()

        app.mount('#app')

        // Clean up static loader
        document.getElementById('app-loader')?.remove()
        document.getElementById('app-loader-styles')?.remove()

        await SplashScreen.hide()
    }

    if (user !== null) {
        const { claims } = await user.getIdTokenResult()
        const userDocId = claims.legacyId ? user.email : user.uid
        if (!userDocId) return

        const { exists } = await initViewer(userDocId)

        if (exists) {
            const displayMode = viewer.value?.displayMode ?? 'auto'
            updateViewerSettings({ displayMode })
            setDisplayMode(displayMode)

            await initAppData()
        }
    }
})

addIcons({
    // Register any Ionicons used in the app
    // Icons with hyphenated names need to be added like this:
    // 'example-icon': exampleIcon
    add,
    'add-circle-outline': addCircleOutline,
    'alert-circle': alertCircle,
    analytics,
    'analytics-outline': analyticsOutline,
    'arrow-back': arrowBack,
    'arrow-back-circle': arrowBackCircle,
    'arrow-forward': arrowForward,
    'arrow-forward-circle': arrowForwardCircle,
    'arrow-up': arrowUp,
    'arrow-down': arrowDown,
    book,
    bookmarks,
    call,
    'call-outline': callOutline,
    calendar,
    camera,
    'caret-back': caretBack,
    'caret-forward': caretForward,
    'caret-up-circle': caretUpCircle,
    'caret-down-circle': caretDownCircle,
    'caret-down-outline': caretDownOutline,
    'caret-up-outline': caretUpOutline,
    chatbox,
    checkmark,
    'checkmark-circle-outline': checkmarkCircleOutline,
    'chevron-back': chevronBack,
    'chevron-down': chevronDown,
    'chevron-forward': chevronForward,
    'chevron-up': chevronUp,
    close,
    'cloud-upload': cloudUpload,
    contract,
    'color-palette': colorPalette,
    create,
    'create-outline': createOutline,
    'document-text': documentText,
    download,
    'download-outline': downloadOutline,
    duplicate,
    ear,
    ellipse,
    'ellipsis-horizontal': ellipsisHorizontal,
    'ellipsis-horizontal-outline': ellipsisHorizontalOutline,
    'ellipse-outline': ellipseOutline,
    'ellipsis-vertical-outline': ellipsisVerticalOutline,
    expand,
    'extension-puzzle': extensionPuzzle,
    eye,
    'eye-off': eyeOff,
    'exit-outline': exitOutline,
    film,
    'filter-outline': filterOutline,
    'first-step-smile': '/assets/first-step-coach/icon/first-step-smile.svg',
    footsteps,
    'happy-face':
        '/assets/mysibtime/icon/sibtime-moments/sibtime-moment-happy-face.svg',
    heart,
    'help-circle-outline': helpCircleOutline,
    home,
    image,
    'information-circle-outline': informationCircleOutline,
    layers,
    library,
    list,
    'lock-closed': lockClosed,
    'logo-closed-captioning': logoClosedCaptioning,
    'lock-open': lockOpen,
    'logo-h5p': '/assets/shared/icon/logo-h5p.svg',
    'logo-youtube': logoYoutube,
    mail,
    'mail-unread': mailUnread,
    'medkit-outline': medkitOutline,
    menu,
    mic,
    'musical-notes': musicalNotes,
    'neutral-face':
        '/assets/mysibtime/icon/sibtime-moments/sibtime-moment-neutral-face.svg',
    newspaper,
    notifications,
    'open-outline': openOutline,
    'pie-chart': pieChart,
    'pie-chart-outline': pieChartOutline,
    pencil,
    people,
    'person-add': personAdd,
    person,
    pause,
    'pause-circle': pauseCircle,
    play,
    'play-circle': playCircle,
    'play-circle-outline': playCircleOutline,
    'play-back': playBack,
    'play-forward': playForward,
    'pulse-outline': pulseOutline,
    'radio-button-on': radioButtonOn,
    'reader-outline': readerOutline,
    'recording-outline': recordingOutline,
    refresh,
    'refresh-outline': refreshOutline,
    remove,
    'remove-circle-outline': removeCircleOutline,
    repeat,
    ribbon,
    rocket: '/assets/beready2smile/icon/brushtool/rocket.svg',
    'sad-face':
        '/assets/mysibtime/icon/sibtime-moments/sibtime-moment-sad-face.svg',
    save,
    scan,
    'scan-circle': scanCircle,
    send,
    settings,
    shapes,
    'sibtime-moments':
        '/assets/mysibtime/icon/sibtime-moments/sibtime-moment-heart.svg',
    speedometer,
    square,
    star,
    'stats-chart': statsChart,
    stop,
    stopwatch,
    sunny,
    text,
    time,
    'thumbs-up': thumbsUp,
    toothbrush: '/assets/beready2smile/icon/brushtool/toothbrush.svg',
    'trail-sign': trailSign,
    trash,
    videocam,
    'videocam-outline': videocamOutline,
    'volume-high': volumeHigh,
    'volume-medium': volumeMedium,
    'volume-low': volumeLow,
    'volume-off': volumeOff,
    warning,
})
