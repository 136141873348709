import { computed, ref } from 'vue'

import {
    firestore,
    deleteField,
    FirebaseDocumentSnapshot,
    FirestoreQuerySnapshot,
    FirestoreWriteBatch,
    serverTimestamp,
    firestoreTimestamp,
} from '@/firebase-config'
import i18n from '@/i18n-config'

import useViewer from '@/composables/global/use-viewer'

import { updateUserNotificationBadge } from '@/utils/callable-cloud-functions'
import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'
import { longToast } from '@/utils/ion-toast'

import { CallRoom } from '@/models/chats/call-room'
import { MemberMetadata } from '@/models/chats/member-metadata'
import { Message } from '@/models/chats/message'

const debug = false

export const MESSAGE_FETCH_COUNT = 15

export type Chat = {
    id: string
    hasUnreadMessages: boolean
    callInProgress?: {
        kind: 'audio' | 'video'
        isRecording: boolean
        screenSharing?: { by: string }
        createdBy: string
    }
    chatMember: MemberMetadata
    messages: Message[]
}

// Caches viewer's chats in a map (chat ID -> Chat)
const chatsMap = ref<Map<string, Chat>>(new Map<string, Chat>())

// Exposes viewer's chats in an array sorted descending by
// most recent message activity
const chatsArray = computed(() => {
    return Array.from(chatsMap.value.entries())
        .map(([, chat]) => chat)
        .sort((a, b) => {
            if (a.messages.length === 0) {
                return 1
            } else if (b.messages.length === 0) {
                return -1
            } else {
                return (
                    b.messages[b.messages.length - 1].createdAt.toMillis() -
                    a.messages[a.messages.length - 1].createdAt.toMillis()
                )
            }
        })
})

const hasUnreadMessages = computed(() => {
    return chatsArray.value.some((chat) => chat.hasUnreadMessages)
})

const callInProgress = computed(() => {
    return chatsArray.value.some((chat) => chat.callInProgress)
})

// Stores all unsubscribe functions for listener cleanup
const allUnsubscribes = ref<(() => void)[]>([])

export default function () {
    type SnapshotType = 'NEW_MESSAGES' | 'OLDER_MESSAGES'

    const { viewer, isOnWeb } = useViewer()

    function removeAllListeners() {
        logger(debug)
        allUnsubscribes.value.forEach((unsub) => unsub())
        allUnsubscribes.value = []
    }

    /**
     * @returns true if message has a valid timestamp and is new to the chat
     */
    function messageIsNewToChat(chat: Chat, message: Message) {
        logger(debug)
        if (message.createdAt === null) {
            // Timestamp has not yet been created by Firebase server, special null case
            return false
        } else if (chat.messages.length > 0) {
            const newerThanNewestCached =
                message.createdAt.toMillis() >
                chat.messages[chat.messages.length - 1].createdAt.toMillis()
            const olderThanOldestCached =
                message.createdAt.toMillis() <
                chat.messages[0].createdAt.toMillis()
            return newerThanNewestCached || olderThanOldestCached
        } else {
            // No cached messages: must be new
            return true
        }
    }

    function updateCachedChat(chat: Chat, newMessage: Message) {
        if (newMessage.isUnread && newMessage.sentBy !== viewer.value?.id) {
            // Chat has new unread message
            chat.hasUnreadMessages = true
        }
        const cachedMessage = chat.messages.find((m) => m.id === newMessage.id)
        if (cachedMessage !== undefined) {
            // Update cached message read status
            cachedMessage.isUnread = newMessage.isUnread
        }
    }

    /**
     * Processes a Firestore query snapshot of a messages collection.
     *
     * @param chatId a chat doc ID
     * @param messagesSnapshot a snapshot containing messages data
     * @returns false if fewer than MESSAGE_FETCH_COUNT messages were added
     */
    function handleMessagesSnapshot(
        chatId: string,
        messagesSnapshot: FirestoreQuerySnapshot,
        snapshotType: SnapshotType
    ): boolean {
        logger(debug, chatId)

        const chat = chatsMap.value.get(chatId)
        if (chat === undefined) return false

        const retrievedMessages = getCollectionDocs<Message>(messagesSnapshot)
        const uncachedMessages = retrievedMessages.filter((message) => {
            updateCachedChat(chat, message)
            return messageIsNewToChat(chat, message)
        })

        switch (snapshotType) {
            case 'NEW_MESSAGES':
                chat.messages = [...chat.messages, ...uncachedMessages]
                break
            case 'OLDER_MESSAGES':
                chat.messages = [...uncachedMessages, ...chat.messages]
                break
        }

        return uncachedMessages.length < MESSAGE_FETCH_COUNT
    }

    function handleMemberSnapshot(
        chatId: string,
        memberSnapshot: FirebaseDocumentSnapshot
    ) {
        logger(debug, chatId)

        const chat = chatsMap.value.get(chatId)
        if (chat === undefined) return

        const updatedMember = docData<MemberMetadata>(memberSnapshot)
        chat.chatMember = updatedMember
    }

    async function handleCallSnapshot(
        chatId: string,
        callSnapshot: FirestoreQuerySnapshot
    ) {
        logger(debug, chatId, callSnapshot)

        const chat = chatsMap.value.get(chatId)
        if (chat === undefined) return

        if (callSnapshot.size === 0) return

        const callRoom = getCollectionDocs<CallRoom>(callSnapshot)[0]

        if (callRoom.inProgress) {
            // Notification for incoming calls on the web
            if (
                callRoom.missedCall && // Check if the call hasn't been answered yet
                callRoom.createdBy != viewer.value?.id &&
                isOnWeb.value
            ) {
                longToast(
                    i18n.global.t('PushNotifications.incomingCall', {
                        user:
                            chat.chatMember.displayName ||
                            callRoom.createdBy.split('@')[0],
                    }),
                    {
                        duration: 5000,
                        icon: callRoom.kind === 'audio' ? 'call' : 'videocam',
                    }
                )
            }

            chat.callInProgress = { ...callRoom }
        } else {
            delete chat.callInProgress
        }
    }

    /**
     * Queries for messages older than those currently cached and prepends
     * them to the chat's messages.
     *
     * @param chatId the ID of a chat
     *
     * @returns a promise that resolves false if there are no older
     * messages to add to the chat
     */
    async function fetchOlderMessages(chatId: string): Promise<boolean> {
        logger(debug, chatId)

        const chat = chatsMap.value.get(chatId)
        let oldestCachedMessageTimestamp = firestoreTimestamp.fromDate(
            new Date()
        )
        if (chat !== undefined && chat.messages.length > 0) {
            oldestCachedMessageTimestamp = chat.messages[0].createdAt
        }

        const messagesSnapshot = await firestore
            .collection('chats')
            .doc(chatId)
            .collection('messages')
            .where('createdAt', '<', oldestCachedMessageTimestamp)
            .orderBy('createdAt')
            .limitToLast(MESSAGE_FETCH_COUNT)
            .get()

        return handleMessagesSnapshot(
            chatId,
            messagesSnapshot,
            'OLDER_MESSAGES'
        )
    }

    /**
     * Initialises a chat, fetches the latest message, and
     * subscribes to the chat's messages subcollection.
     *
     * @param chatDoc the chat document
     * @param chatMemberId the other user ID
     */
    async function initChat(
        chatDoc: FirebaseDocumentSnapshot,
        chatMemberId: string
    ) {
        const chatMemberSnapshot = await chatDoc.ref
            .collection('member-metadata')
            .doc(chatMemberId)
            .get()

        const chatMember = docData<MemberMetadata>(chatMemberSnapshot)

        // Init chat in cache map
        chatsMap.value.set(chatDoc.id, {
            id: chatDoc.id,
            hasUnreadMessages: false,
            chatMember,
            messages: [],
        })

        // Subscribe to member-metadata
        const unsubFromMember = chatDoc.ref
            .collection('member-metadata')
            .doc(chatMemberId)
            .onSnapshot((memberSnapshot) => {
                handleMemberSnapshot(chatDoc.id, memberSnapshot)
            })

        // Subscribe to messages
        const unsubFromMessages: () => void = chatDoc.ref
            .collection('messages')
            .orderBy('createdAt')
            .limitToLast(1)
            .onSnapshot((callSnapshot: FirestoreQuerySnapshot) =>
                handleMessagesSnapshot(chatDoc.id, callSnapshot, 'NEW_MESSAGES')
            )

        // Subscribe to calls
        const unsubFromCalls: () => void = chatDoc.ref
            .collection('call-room')
            .orderBy('createdAt')
            .limitToLast(1)
            .onSnapshot((messagesSnapshot: FirestoreQuerySnapshot) =>
                handleCallSnapshot(chatDoc.id, messagesSnapshot)
            )

        allUnsubscribes.value.push(
            unsubFromMember,
            unsubFromMessages,
            unsubFromCalls
        )
    }

    /**
     * Queries for chats of which the viewer is a member and initializes
     * those which are active and have more than one member.
     *
     * Fails silently if viewer is not initialized at time of invocation.
     */
    async function initChats(): Promise<void | void[]> {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const viewerChatsSnapshot = await firestore
            .collection('chats')
            .where('members', 'array-contains', viewer.value?.id)
            .get()

        return Promise.all(
            viewerChatsSnapshot.docs.map(async (chatSnapshot) => {
                const chatMembers: string[] = chatSnapshot.get('members')
                const chatIsActive =
                    chatSnapshot.get('isActive') && chatMembers.length > 1
                if (chatIsActive) {
                    // Initialize chat
                    const otherChatMember = chatMembers.filter(
                        (member) => member !== viewer.value?.id
                    )
                    await initChat(chatSnapshot, otherChatMember[0])
                }
            })
        )
    }

    /**
     * Writes a message to a chat doc's messages subcollection.
     *
     * @param chatId a chat ID
     * @param text the string message to send to the chat
     */
    async function sendMessage(chatId: string, text: string) {
        logger(debug, chatId, text)
        if (viewer.value?.id === undefined) throw 'Viewer not initialized'

        return firestore
            .collection('chats')
            .doc(chatId)
            .collection('messages')
            .add({
                sentBy: viewer.value.id,
                text,
                createdAt: serverTimestamp(),
                isUnread: true,
            })
    }

    async function batchUpdateChatsDisplayName(
        displayName: string | undefined,
        userId: string,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, userId, displayName)

        const userChatsSnapshot = await firestore
            .collection('chats')
            .where('members', 'array-contains', userId)
            .get()

        if (userChatsSnapshot.empty) return
        userChatsSnapshot.docs.forEach((chat) => {
            const userChatMetadataRef = firestore
                .collection('chats')
                .doc(chat.id)
                .collection('member-metadata')
                .doc(userId)
            batch.set(
                userChatMetadataRef,
                {
                    displayName: displayName ? displayName : deleteField(),
                },
                { merge: true }
            )
        })
    }

    async function batchUpdateChatUserTags(
        tags: string[],
        userId: string,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, tags, userId)

        const userChatsSnapshot = await firestore
            .collection('chats')
            .where('members', 'array-contains', userId)
            .get()

        if (userChatsSnapshot.empty) return
        userChatsSnapshot.docs.forEach((chat) => {
            batch.set(
                firestore
                    .collection('chats')
                    .doc(chat.id)
                    .collection('member-metadata')
                    .doc(userId),
                {
                    tags: tags,
                },
                { merge: true }
            )
        })
    }

    async function setMessagesRead(chatId: string) {
        logger(debug, chatId)
        if (viewer.value?.id === undefined) throw 'Viewer not initialized'
        if (!chatsMap.value.get(chatId)?.hasUnreadMessages) return

        const unreadMessagesSnapshot = await firestore
            .collection('chats')
            .doc(chatId)
            .collection('messages')
            .where('sentBy', '!=', viewer.value.id)
            .where('isUnread', '==', true)
            .get()

        const batch = firestore.batch()
        unreadMessagesSnapshot.forEach((messageSnapshot) => {
            batch.update(messageSnapshot.ref, { isUnread: false })
        })

        batch.set(
            firestore
                .collection('chats')
                .doc(chatId)
                .collection('member-metadata')
                .doc(viewer.value.id),
            {
                id: viewer.value.id,
                lastReadAt: serverTimestamp(),
            },
            { merge: true }
        )

        await batch.commit()

        const chat = chatsMap.value.get(chatId)
        if (chat !== undefined) chat.hasUnreadMessages = false

        return updateUserNotificationBadge(viewer.value.id)
    }

    function deinitChats() {
        logger(debug)
        chatsMap.value = new Map<string, Chat>()
        removeAllListeners()
    }

    return {
        chatsArray,
        chatsMap,
        callInProgress,
        hasUnreadMessages,

        batchUpdateChatsDisplayName,
        batchUpdateChatUserTags,
        deinitChats,
        fetchOlderMessages,
        initChats,
        removeAllListeners,
        sendMessage,
        setMessagesRead,
    }
}
