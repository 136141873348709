import { ref, readonly } from 'vue'

import { logger } from '@/utils/debug'
import { isValidColor } from '@/utils/strings'

const debug = false

const appTitle = ref('')
const backgroundColor = ref()
const textColor = ref()

const menuButtonBack = ref(false)
const isBackToRootRoute = ref(true)

const isMenuCollapsed = ref(true)

export default function () {
    function setAppTitle(newValue: string | undefined) {
        logger(debug, newValue)
        appTitle.value = newValue ?? ''
    }

    function setToolbarStyle(newValue: {
        backgroundColor?: string
        textColor?: string
    }) {
        logger(debug, newValue)
        backgroundColor.value = isValidColor(newValue.backgroundColor)
            ? newValue.backgroundColor
            : undefined
        textColor.value = isValidColor(newValue.textColor)
            ? newValue.textColor
            : undefined
    }

    function setMenuButtonBack(newValue: boolean, backToRootRoute = true) {
        logger(debug, newValue)
        menuButtonBack.value = newValue
        isBackToRootRoute.value = backToRootRoute
    }

    /**
     * Toggles appearance of menu between collapsed icon-only view
     * and full view. Applicable only when menu is visible in split
     * pane, i.e. on medium and larger displays.
     */
    function toggleMenuSize() {
        logger(debug)
        isMenuCollapsed.value = !isMenuCollapsed.value
    }

    function deinitShellHeader() {
        appTitle.value = ''
        backgroundColor.value = undefined
        textColor.value = undefined
        menuButtonBack.value = false
        isBackToRootRoute.value = true
        isMenuCollapsed.value = true
    }

    return {
        appTitle: readonly(appTitle),
        backgroundColor: readonly(backgroundColor),
        isBackToRootRoute: readonly(isBackToRootRoute),
        isMenuCollapsed: readonly(isMenuCollapsed),
        menuButtonBack: readonly(menuButtonBack),
        textColor: readonly(textColor),

        deinitShellHeader,
        setAppTitle,
        setMenuButtonBack,
        setToolbarStyle,
        toggleMenuSize,
    }
}
