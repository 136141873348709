// Regular expressions
const digitsOnlyRegex = /^\d+$/
const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const validH5pUrlRegex =
    /^https:\/\/[A-Za-z0-9]+\.h5p\.com\/content\/[0-9]+\/embed$/
const validYoutubeUrlRegex =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube(-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/

const validCustomMarkdownTooltipRegex = /\[tooltip\]\(([^"]+)\s*"([^"]+)"\)/g
const validCustomHTMLTooltipRegex = /@@tooltip@@\(([^,]+),([^)]+)\)/g

export function humanizeCamel(str: string) {
    // Capitalize first word as well and add spaces between each
    const spaced = str.replace(/([A-Z])/g, ' $1')
    const result = spaced.charAt(0).toUpperCase() + spaced.slice(1)
    return result
}

export function humanizeSnake(str: string) {
    // Replace underscores with spaces & capitalize each word
    const frags = str.split('_')
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
}

export function humanizeKabob(str: string) {
    // Replace hyphens with spaces & capitalize each word
    const frags = str.split('-')
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
}

export function hasOnlyDigits(str: string) {
    // Return true if a string contains only digits
    return digitsOnlyRegex.test(str)
}

export function isValidEmail(email: string) {
    return String(email).toLowerCase().match(validEmailRegex)
}

export function isValidColor(color: string | undefined) {
    if (color === undefined) return false
    return CSS.supports('color', color)
}

export function isValidH5pUrl(url: string) {
    return validH5pUrlRegex.test(url)
}

export function isValidYouTubeUrl(url: string) {
    return validYoutubeUrlRegex.test(url)
}

export function parseYouTubeUrl(youtubeUrl: string) {
    const regexMatchArray = youtubeUrl.match(validYoutubeUrlRegex)
    if (regexMatchArray === null) return undefined

    const id = regexMatchArray[2]
    const searchParams = new URL(youtubeUrl).searchParams
    const start =
        (searchParams.get('t') || searchParams.get('start')) ?? undefined
    return { id, start }
}

export function isValidURL(url: string) {
    // Regular expression pattern to match URLs
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/
    return urlPattern.test(url)
}

export function toFileNameFormat(str: string) {
    // Reduces and replaces any group of non-word characters from input string to a single dash
    // Removes trailing dash if exists
    return str
        .trim()
        .replace(/([^a-z0-9]+)/gi, '-')
        .replace(/-$/, '')
}

export function camelToKebab(str: string) {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

export function getSingularFeatureTitle(title: string | undefined) {
    if (!title) return ''
    return title.split(' | ')[0]
}
export function getPluralFeatureTitle(title: string | undefined) {
    if (!title) return ''
    const label = title.split(' | ')[1]
    return label || getSingularFeatureTitle(title)
}
// Pre-process the Markdown to replace custom tooltip syntax with a placeholder
export function replaceCustomMarkdownTooltip(str: string) {
    if (!str) return str
    return str.replace(
        validCustomMarkdownTooltipRegex,
        (match, p1, p2) => `@@tooltip@@(${p1},${p2})`
    )
}

// Post-process the HTML to replace the placeholders with custom tooltip HTML.
export function replaceCustomHTMLTooltip(str: string) {
    if (!str) return str
    //The class names are used for styling and are defined in the global CSS.
    return str.replace(validCustomHTMLTooltipRegex, (match, p1, p2) => {
        return ` <a class="rendered-markdown-tooltip">${p1}</a><span class="rendered-markdown-tooltip-text">${p2}</span>`
    })
}

// Remove custom tooltip syntax from the string. Syntax Example: [tooltip](hover text "Lorem ipsum")
export function removeCustomMarkdownTooltip(str: string) {
    return str.replace(validCustomMarkdownTooltipRegex, '$1')
}

/**
 * Returns a random string of the given length using chars specified by charSet.
 * If the generated string happens to be in the notIn array, it will recurse
 * until it creates a string that's not in notIn array.
 * @param length number of characters in the string
 * @param options
 * @returns random alpha string
 */
export function generateRandomString(options: {
    length: number
    charSet: 'alpha' | 'numeric' | 'alphanumeric'
    notIn?: string[]
}) {
    let result = ''
    const alphaCharacters =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numericCharacters = '0123456789'
    const characters =
        options.charSet === 'alpha'
            ? alphaCharacters
            : options.charSet === 'numeric'
              ? numericCharacters
              : alphaCharacters + numericCharacters
    const charactersLength = characters.length
    for (let i = 0; i < options.length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }

    if (options?.notIn && options.notIn.includes(result)) {
        return generateRandomString(options)
    } else {
        return result
    }
}
