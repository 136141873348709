import { HttpsError } from '@/models/_utils/errors'

export function throwError(message: string): never {
    throw new Error(message)
}

export function getErrorMessage(error: unknown): string {
    return error instanceof Error ? error.message : String(error)
}

export function ensureError(error: unknown): Error {
    return error instanceof Error ? error : new Error(getErrorMessage(error))
}

export function isHttpsError(error: unknown): error is HttpsError {
    return error instanceof Error && 'code' in error && 'details' in error
}
