import { readonly, ref } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { cleanObjForFirestore, getCollectionDocs } from '@/utils/firestore'

import { EnrollmentCode } from '@/models/enrollment-code'

const debug = false

const enrollmentCodes = ref<EnrollmentCode[]>([])

export default function () {
    const { viewer, isAdmin, isManager } = useViewer()

    async function initEnrollmentCodes() {
        logger(debug, 'initEnrollmentCodes')

        if (viewer.value === undefined) throw new Error('Viewer is undefined')
        if (isAdmin.value || isManager.value) {
            const enrollmentCodesData = await firestore
                .collection('enrollment-codes')
                .get()
                .then(getCollectionDocs<EnrollmentCode>)

            enrollmentCodes.value = enrollmentCodesData
        } else {
            logger(
                debug,
                "Viewer is not a manager or admin, don't fetch enrollment codes"
            )
        }
    }

    function deinitEnrollmentCodes() {
        logger(debug, 'deinitEnrollmentCodes')

        enrollmentCodes.value = []
    }

    async function updateEnrollmentCodes(
        updatedEnrollmentCodes: EnrollmentCode[]
    ) {
        logger(debug, 'updateEnrollmentCodes')
        if (viewer.value === undefined) throw new Error('Viewer is undefined')
        if (!isAdmin.value || !isManager.value) {
            throw new Error('Viewer is not an admin')
        }

        await initEnrollmentCodes()
        const batch = firestore.batch()

        // Update existing codes
        for (const enrollmentCode of updatedEnrollmentCodes) {
            const enrollmentCodeRef = firestore
                .collection('enrollment-codes')
                .doc(enrollmentCode.id)
            batch.set(enrollmentCodeRef, {
                ...cleanObjForFirestore('set', enrollmentCode),
                updatedAt: serverTimestamp(),
                updatedBy: viewer.value.id,
            })
        }

        // Delete removed codes
        for (const enrollmentCode of enrollmentCodes.value) {
            if (
                updatedEnrollmentCodes.some((e) => e.id === enrollmentCode.id)
            ) {
                continue
            } else {
                const enrollmentCodeRef = firestore
                    .collection('enrollment-codes')
                    .doc(enrollmentCode.id)
                batch.delete(enrollmentCodeRef)
            }
        }

        await batch.commit()
        enrollmentCodes.value = updatedEnrollmentCodes
    }

    return {
        enrollmentCodes: readonly(enrollmentCodes),

        initEnrollmentCodes,
        deinitEnrollmentCodes,
        updateEnrollmentCodes,
    }
}
