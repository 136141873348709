import { AudioPlayer } from '@mediagrid/capacitor-native-audio'
import { computed, ref, readonly, watch } from 'vue'

import appConfig from '@/app-config'

import useViewer from '@/composables/global/use-viewer'

const { isOnWeb } = useViewer()

const currentView = computed(() => (isOnWeb.value ? 'web' : 'mobile'))

//mobile
const mobileCurrentPositionIntervalId = ref<number | undefined>()

//web
const webAudio = ref<HTMLAudioElement | undefined>(undefined)

//both
const audioName = ref()
const existingAudioId = ref<string>('')
const isInitialized = ref(false)
const isPlaying = ref(false)
const currentTime = ref(0)
const duration = ref(0)
const volume = ref(100)
const barChangeIsUserInput = ref(false)

//popover
const popoverEvent = ref<Event>()
const popoverOpen = ref(false)

export default function () {
    async function initializeGlobalAudioPlayer(
        src: string,
        audioId: string,
        title: string,
        playOnInitialize: boolean = true
    ) {
        openPopover()

        if (isInitialized.value === true) {
            if (existingAudioId.value === audioId) {
                if (!isPlaying.value) togglePlay()
                return
            } else {
                await pauseAudio()
                await destroyAudio()
                isPlaying.value = false
                currentTime.value = 0
                duration.value = 0
            }
        }
        isInitialized.value = true
        existingAudioId.value = audioId
        audioName.value = title

        // Mobile //
        if (currentView.value === 'mobile') {
            await AudioPlayer.create({
                audioId,
                audioSource: src,
                friendlyTitle: title,
                useForNotification: true,
                artworkSource: `assets/${appConfig.projectId}/image/logo.png`,
                isBackgroundMusic: false,
                loop: false,
            })

            await AudioPlayer.onAudioReady({ audioId }, async () => {
                const result = await AudioPlayer.getDuration({ audioId })
                duration.value = Math.round(result.duration)
            })

            await AudioPlayer.onAudioEnd({ audioId }, () => {
                stopCurrentPositionUpdate()
            })

            await AudioPlayer.onPlaybackStatusChange({ audioId }, (result) => {
                switch (result.status) {
                    case 'playing':
                        startCurrentPositionUpdate()
                        break
                    case 'paused':
                        stopCurrentPositionUpdate()
                        break
                    case 'stopped':
                        stopCurrentPositionUpdate()
                }
            })

            await AudioPlayer.initialize({ audioId })

            // Web //
        } else if (currentView.value === 'web') {
            webAudio.value = new Audio(src)
            webAudio.value.addEventListener('loadedmetadata', () => {
                duration.value = webAudio.value?.duration ?? 0
            })
            webAudio.value.addEventListener('timeupdate', () => {
                if (!barChangeIsUserInput.value)
                    currentTime.value = webAudio.value?.currentTime ?? 0
            })
            webAudio.value.addEventListener('ended', () => {
                isPlaying.value = false
            })
        }

        //setTimeout needed for event listener callbacks to sync so lockscreen ui works appropriately
        if (playOnInitialize) {
            setTimeout(async () => {
                await togglePlay()
                isPlaying.value = true
            }, 1000)
        }
    }

    async function updateTime(timeValue: number) {
        if (!barChangeIsUserInput.value) return

        currentTime.value === Math.ceil(timeValue)
        if (currentView.value === 'mobile') {
            await AudioPlayer.seek({
                audioId: existingAudioId.value,
                timeInSeconds: Math.ceil(timeValue),
            })
        } else if (currentView.value === 'web' && webAudio.value) {
            webAudio.value.currentTime = Math.ceil(timeValue)
            isPlaying.value = true
        }
    }

    async function updateVolume(volumeValue: number) {
        volume.value = volumeValue
        if (currentView.value === 'mobile') {
            await AudioPlayer.setVolume({
                audioId: existingAudioId.value,
                volume: volumeValue / 100,
            })
        } else if (currentView.value === 'web' && webAudio.value) {
            webAudio.value.volume = volumeValue / 100
        }
    }

    async function startBarChange() {
        barChangeIsUserInput.value = true
        await pauseAudio()
    }

    async function endBarChange() {
        barChangeIsUserInput.value = false
        await playAudio()
    }

    async function togglePlay() {
        isPlaying.value ? await pauseAudio() : await playAudio()
        if (currentView.value === 'web') {
            isPlaying.value = !isPlaying.value
        }
        if (currentTime.value === duration.value) {
            currentTime.value = 0
        }
    }

    async function playAudio() {
        if (currentView.value === 'mobile') {
            await AudioPlayer.play({ audioId: existingAudioId.value })
            startCurrentPositionUpdate()
        } else if (currentView.value === 'web') {
            webAudio.value?.play()
        }
    }

    async function pauseAudio() {
        if (currentView.value === 'mobile') {
            await AudioPlayer.pause({ audioId: existingAudioId.value })
            stopCurrentPositionUpdate()
        } else if (currentView.value === 'web') {
            webAudio.value?.pause()
        }
    }

    async function destroyAudio() {
        if (currentView.value === 'mobile') {
            stopCurrentPositionUpdate()
            await AudioPlayer.destroy({ audioId: existingAudioId.value })
        } else if (currentView.value === 'web') {
            webAudio.value?.remove()
        }
    }

    //mobile functions
    function startCurrentPositionUpdate() {
        stopCurrentPositionUpdate()

        isPlaying.value = true
        mobileCurrentPositionIntervalId.value = window.setInterval(async () => {
            const audioId = existingAudioId.value
            const result = await AudioPlayer.getCurrentTime({ audioId })
            currentTime.value = Math.round(result.currentTime)
        }, 1000)
    }

    function stopCurrentPositionUpdate() {
        isPlaying.value = false
        clearInterval(mobileCurrentPositionIntervalId.value)
        mobileCurrentPositionIntervalId.value = 0
    }

    function openPopover(e?: Event) {
        if (e) {
            popoverEvent.value = e
            popoverOpen.value = true
        } else {
            const popoverButtonElement = document.getElementById(
                'media-player-button'
            )
            popoverButtonElement?.click()
        }
    }

    function closePopover() {
        popoverOpen.value = false
    }

    //needed beacuse sometimes positionUpdate interval time inconsistencies would cause desync,
    // and onAudioEnd event listener callback would never fire
    watch(currentTime, async () => {
        if (
            currentTime.value >= duration.value &&
            currentTime.value > 0 &&
            currentView.value === 'mobile'
        ) {
            await AudioPlayer.stop({ audioId: existingAudioId.value })
            stopCurrentPositionUpdate()
        }
    })

    return {
        audioIsInitialized: readonly(isInitialized),
        audioName: readonly(audioName),
        currentTime: readonly(currentTime),
        duration: readonly(duration),
        isPlaying: readonly(isPlaying),
        popoverEvent: readonly(popoverEvent),
        popoverOpen: readonly(popoverOpen),
        volume: readonly(volume),

        closePopover,
        endBarChange,
        initializeGlobalAudioPlayer,
        openPopover,
        startBarChange,
        togglePlay,
        updateTime,
        updateVolume,
    }
}
