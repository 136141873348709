import { computed, readonly, Ref, ref } from 'vue'

import {
    firestore,
    FirebaseDocumentSnapshot,
    serverTimestamp,
    FirestoreQuerySnapshot,
} from '@/firebase-config'

import useUsers from '@/composables/global/use-users'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import {
    cleanObjForFirestore,
    docData,
    getCollectionDocs,
} from '@/utils/firestore'
import { nonNullish } from '@/utils/types'

import { User } from '@/models/user'
import { GuideSession } from '@/models/users/guide-session'

const debug = false

export type UserSession = {
    userId: string
    userDisplayName?: string
    userName: string
    userResearchId: string
    session: GuideSession
}

export type GuideSessionForm = Omit<
    GuideSession,
    'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'
>

const adverseEventSessions = ref<UserSession[]>([])
// sorted usersSessions, by created date, from most recent to oldest.
const sortedAdverseEventSessions = computed(() => {
    return adverseEventSessions.value.sort((a, b) => {
        return b.session.createdAt.toMillis() - a.session.createdAt?.toMillis()
    })
})

const userSessions: Ref<GuideSession[]> = ref([])

const sessionsOfParticipants: Ref<UserSession[]> = ref([])
const sortedGuideSessionsOfParticipants = computed(() => {
    return sessionsOfParticipants.value.sort((a, b) => {
        return b.session.createdAt.toMillis() - a.session.createdAt.toMillis()
    })
})

export default function () {
    const { isUserInActiveCourse, getUser } = useUsers()
    const { viewer } = useViewer()

    async function fetchUserSessions(userId: string) {
        logger(debug, userId)

        const userSessionsSnapshot = await firestore
            .collection('users')
            .doc(userId)
            .collection('guide-sessions')
            .orderBy('startTime', 'desc')
            .get()

        userSessions.value =
            getCollectionDocs<GuideSession>(userSessionsSnapshot)
    }

    async function addSession(userId: string, fields: GuideSessionForm) {
        logger(debug, userId, fields)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        await firestore
            .collection('users')
            .doc(userId)
            .collection('guide-sessions')
            .doc()
            .set({
                ...cleanObjForFirestore('set', fields, {
                    keepEmptyObjects: false,
                    keepEmptyArrays: false,
                }),
                createdAt: serverTimestamp(),
                createdBy: viewer.value.id,
            })

        await fetchUserSessions(userId)
    }

    async function deleteSession(userId: string, sessionId: string) {
        logger(debug, userId, sessionId)

        await firestore
            .collection('users')
            .doc(userId)
            .collection('guide-sessions')
            .doc(sessionId)
            .delete()

        userSessions.value = userSessions.value.filter(
            (session) => session.id !== sessionId
        )
    }

    async function updateGuideSession(
        userId: string,
        sessionId: string,
        updatedFields: GuideSessionForm
    ) {
        logger(debug, userId, sessionId, updatedFields)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        await firestore
            .collection('users')
            .doc(userId)
            .collection('guide-sessions')
            .doc(sessionId)
            .update({
                ...cleanObjForFirestore('update', updatedFields, {
                    keepEmptyObjects: false,
                    keepEmptyArrays: false,
                    markAllUnwantedFieldsForDeletion: true,
                }),
                updatedAt: serverTimestamp(),
                updatedBy: viewer.value.id,
            })

        await fetchUserSessions(userId)
    }

    async function handleSessionSnapshot(
        sessionSnapshot: FirebaseDocumentSnapshot
    ) {
        logger(debug, sessionSnapshot)

        const retrievedSession = docData<GuideSession>(sessionSnapshot)
        const hasAdverseEvents =
            Object.keys(retrievedSession.adverseEvents?.events ?? {}).length > 0
        if (!hasAdverseEvents) return undefined

        const userId = sessionSnapshot.ref.parent.parent?.id
        if (!userId) return undefined // Snapshot may belong to a deleted user
        const userIsInActiveCourse = await isUserInActiveCourse(userId)
        if (!userIsInActiveCourse) return undefined

        const user = await sessionSnapshot.ref.parent.parent
            .get()
            .then(docData<User>)

        return {
            userId: user.id,
            userDisplayName: user.displayName,
            userName: user.name,
            userResearchId: user.researchId ?? '',
            session: retrievedSession,
        }
    }

    async function fetchAdverseEventSessions(startDate: Date, endDate: Date) {
        logger(debug, { startDate, endDate })

        const sessionsSnapshot = await firestore
            .collectionGroup('guide-sessions')
            .orderBy('createdAt', 'desc')
            .where('createdAt', '>', startDate)
            .where('createdAt', '<', endDate)
            .get()

        const sessions = await Promise.all(
            sessionsSnapshot.docs.map(
                async (sessionSnapshot) =>
                    await handleSessionSnapshot(sessionSnapshot)
            )
        )

        adverseEventSessions.value = sessions.filter(nonNullish)
    }

    async function fetchUserLastAdverseEvent(userId: string) {
        logger(debug, userId)

        const guideSessionSnapshot = await firestore
            .collection('users')
            .doc(userId)
            .collection('guide-sessions')
            .get()

        if (guideSessionSnapshot.empty) return undefined

        const adverseEventSessions = await Promise.all(
            guideSessionSnapshot.docs.map(
                async (sessionSnapshot) =>
                    await handleSessionSnapshot(sessionSnapshot)
            )
        )
        if (adverseEventSessions.length === 0) return undefined
        const adverseEventUserSessions = adverseEventSessions.filter(nonNullish)

        const sortedAdverseEventData = adverseEventUserSessions.sort((a, b) => {
            const timeA = new Date(a.session.startTime).getTime()
            const timeB = new Date(b.session.startTime).getTime()
            return timeB - timeA
        })
        if (sortedAdverseEventData.length === 0) return undefined
        return sortedAdverseEventData[0]
    }

    async function fetchUserLastGuideSession(
        userId: string
    ): Promise<GuideSession | undefined> {
        logger(debug, userId)

        const guideSessionSnapshot = await firestore
            .collection('users')
            .doc(userId)
            .collection('guide-sessions')
            .orderBy('createdAt')
            .where('contacted', '==', 'yes')
            .limitToLast(1)
            .get()

        if (guideSessionSnapshot.empty) return undefined

        return docData<GuideSession>(guideSessionSnapshot.docs[0])
    }

    function getFilteredSessionsOfParticipants(
        sessionsSnapshot: FirestoreQuerySnapshot
    ) {
        logger(debug)

        const newParticipantSessions = sessionsSnapshot.docs
            .map((doc) => {
                const userId = doc.ref.parent?.parent?.id
                if (!userId) return

                const user = getUser(userId)
                if (user?.researchId === undefined || user.researchId === '') {
                    return
                }

                const sessionDoc = docData<GuideSession>(doc)
                if (sessionDoc.createdAt === null) return // document is being created

                return {
                    userId: user.id,
                    userName: user.name,
                    userResearchId: user.researchId,
                    session: sessionDoc,
                    ...(user.displayName && {
                        userDisplayName: user.displayName,
                    }),
                }
            })
            .filter((doc): doc is UserSession => !!doc)

        return newParticipantSessions
    }

    async function loadSessionsOfParticipants() {
        logger(debug)

        const sessionsSnapshot = await firestore
            .collectionGroup('guide-sessions')
            .orderBy('createdAt', 'desc')
            .get()

        sessionsOfParticipants.value =
            getFilteredSessionsOfParticipants(sessionsSnapshot)
    }

    function deinitSessions() {
        logger(debug)
        adverseEventSessions.value = []
        userSessions.value = []
        sessionsOfParticipants.value = []
    }

    return {
        userSessions: readonly(userSessions),
        sessionsOfParticipants,
        sortedAdverseEventSessions,
        sortedGuideSessionsOfParticipants,

        addSession,
        deinitSessions,
        deleteSession,
        fetchAdverseEventSessions,
        fetchUserLastAdverseEvent,
        fetchUserLastGuideSession,
        fetchUserSessions,
        loadSessionsOfParticipants,
        updateGuideSession,
    }
}
